import React from "react"
import classNames from "classnames/bind"
import { Link } from "gatsby"

import ArrowRight from "../images/arrow-right.svg"
import styles from "../styles/components/button.module.scss"
const cx = classNames.bind(styles)

const ButtonInner = ({
  border,
  green,
  short,
  black,
  centered,
  whiteGreen,
  text,
}) => (
  <div
    className={cx("buttonWrapper", {
      border,
      green,
      black,
      whiteGreen,
    })}
  >
    <div
      className={cx("buttonInner", {
        border,
        green,
        short,
        black,
        centered,
        whiteGreen,
      })}
    >
      <span>{text}</span>
      <ArrowRight />
    </div>
  </div>
)

const Button = props => {
  let {
    border,
    green,
    black,
    whiteGreen,
    link,
    short,
    onClick,
    externalLink,
    centered,
    type,
  } = props
  return (
    <>
      {link && (
        <Link
          to={link}
          className={cx("button", {
            border,
            green,
            short,
            black,
            centered,
            whiteGreen,
          })}
        >
          <ButtonInner {...props} />
        </Link>
      )}
      {(onClick || type) && (
        <button
          label="button"
          onClick={onClick}
          type={type}
          className={cx("button", {
            border,
            green,
            short,
            black,
            centered,
            whiteGreen,
          })}
        >
          <ButtonInner {...props} />
        </button>
      )}
      {externalLink && (
        <a
          href={externalLink}
          rel="noopener noreferrer"
          target="_blank"
          className={cx("button", {
            border,
            green,
            short,
            black,
            centered,
            whiteGreen,
          })}
        >
          <ButtonInner {...props} />
        </a>
      )}
    </>
  )
}

export default Button
