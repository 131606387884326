import React from "react"
import Reveal from "react-reveal/Reveal"
import styles from "../../styles/components/home/our-values.module.scss"
import Fade from "react-reveal/Fade"

import GreenDash from "../../images/green-dash.svg"

import Flag1 from "../../images/our-values-flag-1.svg"
import Flag2 from "../../images/our-values-flag-2.svg"
import Flag3 from "../../images/our-values-flag-3.svg"

const OurValues = props => {
  return (
    <div className={styles.container}>
      <Fade left cascade>
        <div className={styles.flag1}>
          <Flag1 />
        </div>
        <div className={styles.flag2}>
          <Flag2 />
        </div>
        <div className={styles.flag3}>
          <Flag3 />
        </div>
      </Fade>
      <div className={styles.text}>
        <Reveal effect="fadeIn">
          <div className={styles.header}>{props.header}</div>
        </Reveal>
        {props.subtext && (
          <div
            className={styles.subtext}
            dangerouslySetInnerHTML={{
              __html: props.subtext.childMarkdownRemark.html,
            }}
          />
        )}
      </div>
      <div className={styles.cardsContainer}>
        {props.textList.map((card, i) => {
          return (
            <Reveal effect="fadeInFromLeft" duration={i * 100} key={i}>
              <div className={styles.card}>
                <GreenDash className={styles.dash} />
                <div>{card}</div>
              </div>
            </Reveal>
          )
        })}
      </div>
    </div>
  )
}

export default OurValues
