import React from "react"
import Reveal from "react-reveal/Reveal"
import LazyLoad from "react-lazyload"
import styles from "../../styles/components/home/staggered-grid.module.scss"
import "../../styles/animations.scss"
import Tile from "../tile"
import Button from "../button"

class StaggeredGrid extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      numColumns: 2,
      activeTileIndex: -1,
      fadeGrid: false,
      zIndex: 0,
    }

    this.setNumColumns = this.setNumColumns.bind(this)
  }

  componentDidMount() {
    let screenWidth = window.innerWidth
    if (screenWidth >= 1200) {
      this.setState({ numColumns: 4 })
    } else if (screenWidth >= 768) {
      this.setState({ numColumns: 3 })
    }
    window.addEventListener("resize", this.setNumColumns)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setNumColumns)
  }

  setNumColumns() {
    if (window !== undefined) {
      let screenWidth = window.innerWidth
      if (screenWidth >= 1200) {
        this.setState({ numColumns: 4 })
      } else if (screenWidth >= 830) {
        this.setState({ numColumns: 3 })
      } else this.setState({ numColumns: 2 })
    }
  }

  setActiveTileIndex(i) {
    this.setState({ activeTileIndex: i })
  }

  toggleGridFade() {
    this.setState({ fadeGrid: !this.state.fadeGrid })
  }

  render() {
    //this.setNumColumns()

    const numTiles = this.props.grid.tiles
      .length /* Contentful requires exactly 12 tiles */

    /* 2 x 2 array : rows (i) x cols (j) */
    //console.log(numTiles)
    let numRows = numTiles / this.state.numColumns
    //let gridMatrix = [numRows][this.numColumns]

    let tileGrid = []

    /* iterate by columns */
    for (let j = 0; j < this.state.numColumns; j++) {
      let col = []
      for (let i = 0; i < numRows; i++) {
        let currTileIndex = i * 4 + j
        let currTile = this.props.grid.tiles[currTileIndex]

        //console.log("[" + i + ", " + j + "]: " + (i * 4 + j))
        if (this.state.numColumns === 3 && i === 3) {
          currTile = this.props.grid.tiles[j * numRows + i]
        }

        if (this.state.numColumns === 2 && i > 2) {
          currTile = this.props.grid.tiles[(i - 3) * 4 + (j + 2)]
        }

        let zIndex = 0
        if (currTile.__typename === "ContentfulCompany") {
          zIndex = 5
        }
        if (i * this.state.numColumns + j === this.state.activeTileIndex) {
          zIndex = 10
        }

        col.push(
          <div key={i} style={{ position: "relative", zIndex: zIndex }}>
            <Reveal effect="fadeIn" delay={i * this.state.numColumns + j}>
              <Tile
                showFlagsOnTitle={this.props.grid.showFlagsOnTitle}
                key={(i * this.state.numColumns).toString() + j.toString()}
                handleMouseEnter={this.setActiveTileIndex.bind(this)}
                handleMouseLeave={this.setActiveTileIndex.bind(this)}
                index={i * this.state.numColumns + j}
                active={
                  this.state.activeTileIndex === i * this.state.numColumns + j
                }
                fade={this.state.fadeGrid && this.state.activeTileIndex !== -1}
                {...currTile}
              />
            </Reveal>
          </div>
          /* <div
            onMouseEnter={() =>
              this.setActiveTileIndex(i * this.state.numColumns + j)
            }
            onMouseLeave={() => this.setActiveTileIndex(-1)}
            className={cx("tile", {
              fade: this.state.fadeGrid && this.state.activeTileIndex != -1,
              active:
                this.state.activeTileIndex === i * this.state.numColumns + j,
            })}
          >
            <div className={cx("tileInner", { rotate: currTile.back })}>
              {currTile.back && (
                <div className={styles.tileBack}>
                  <span>Back of Tile</span>
                </div>
              )}
              <img
                src={this.props.tiles[i * this.state.numColumns + j].front}
              />
            </div>
          </div> */
        )
      }
      tileGrid.push(col)
      //console.log(col)
    }

    // populate grid
    // for (let i = 0; i < numRows; i++) {
    //   // get the size of the inner array
    //   // loop the inner array
    //   let row = []
    //   for (let j = 0; j < this.state.numColumns; j++) {
    //     //gridMatrix[i][j] = tiles[i * this.state.numColumns + j]
    //     row.push(
    //       <div className={styles.tile}>
    //         <img src={this.props.tiles[i * this.state.numColumns + j]} />
    //       </div>
    //     )
    //   }
    //   tileGrid.push(row)
    //   console.log(row)
    // }

    for (let i = 0; i < this.state.numColumns; i++) {}
    return (
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <Reveal effect="fadeIn">
            <div className={styles.header}>{this.props.header}</div>
          </Reveal>
          <div className={styles.flexContainer}>
            <Reveal effect="fadeInFromLeft">
              <div
                className={styles.subtext}
                dangerouslySetInnerHTML={{
                  __html: this.props.subtext.childMarkdownRemark.html,
                }}
              />
            </Reveal>
            <Reveal effect="fadeIn">
              <div className={styles.buttonWrapper}>
                <Button
                  link="/our-community"
                  text="Meet Our Community"
                  border
                  green
                />
              </div>
            </Reveal>
          </div>
        </div>
        <LazyLoad>
          <div
            className={styles.grid}
            onMouseEnter={this.toggleGridFade.bind(this)}
            onMouseLeave={this.toggleGridFade.bind(this)}
          >
            {tileGrid.map((col, i) => {
              return (
                <div className={styles.column} key={i}>
                  {col}
                </div>
              )
            })}
          </div>
        </LazyLoad>
      </div>
    )
  }
}

export default StaggeredGrid
