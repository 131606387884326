import React from "react"

import styles from "../styles/components/tweet.module.scss"

const Tweet = ({ time, content, link }) => {
  return (
    <a
      className={styles.tweet}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles.label}>{time}</div>
      <p
        className={styles.paragraph}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </a>
  )
}

export default Tweet
