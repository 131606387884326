import React from "react"
import { Link } from "gatsby"
import Reveal from "react-reveal/Reveal"
import { Swipeable } from "react-swipeable"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import Img from "gatsby-image"
import classNames from "classnames/bind"
import styles from "../../styles/components/home/fading-carousel.module.scss"
import "../../styles/animations.scss"

//import GreenArrow from "../../images/green-arrow.svg"
import CarouselBackdrop from "../../images/carousel-backdrop.svg"
import CarouselMobileFlag from "../../images/carousel-mobile-flag.svg"
import BackgroundPlaceholder from "../../images/carousel-background-placeholder.jpg"

const cx = classNames.bind(styles)

const Sliders = props => {
  const { count, activeSlide, selectSlide } = props
  let dashArray = []

  for (let i = 0; i < count; i++) {
    dashArray.push(
      <button
        key={i}
        aria-label={`View Portfolio Company ${i}`}
        className={styles.dashBoundingBox}
        onClick={() => selectSlide(i)}
      >
        <div className={cx("dash", { active: i === activeSlide })} />
      </button>
    )
  }
  return <div className={styles.slidersContainer}>{dashArray}</div>
}

const FadingCarouselItem = props => {
  return (
    <div className={styles.dropShadow}>
      <CarouselBackdrop />
      <div className={styles.box}>
        <Swipeable
          onSwipedLeft={e => props.handleSwipe(1)}
          onSwipedRight={e => props.handleSwipe(0)}
          className={styles.swipe}
        >
          <Link to={`/${props.slug}`} className={styles.pageLink}>
            <div className={styles.content}>
              <Reveal effect="fadeIn" duration={400}>
                <Img
                  className={styles.logo}
                  fluid={props.logo.fluid}
                  alt={props.name}
                />
              </Reveal>
              <Reveal effect="fadeInUp" duration={400}>
                <div className={styles.description}>{props.description}</div>
              </Reveal>
            </div>
          </Link>
        </Swipeable>
        <div className={styles.flexContainer}>
          <Sliders
            count={props.slideCount}
            activeSlide={props.activeSlide}
            selectSlide={props.selectSlide}
          />
          {/* <Link to={`/${props.slug}`} className={styles.arrow}>
            <GreenArrow />
          </Link> */}
        </div>
      </div>
    </div>
  )
}

class FadingCarousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = { activeSlide: 0, slideCount: this.props.slides.length }

    this.selectSlide = this.selectSlide.bind(this)
    this.incrementSlide = this.incrementSlide.bind(this)
  }

  componentDidMount() {
    this.interval = setInterval(() => this.incrementSlide(true), 10000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  incrementSlide(isNext) {
    const slideCount = this.state.slideCount
    let activeSlide = this.state.activeSlide + (isNext ? 1 : -1)
    if (activeSlide < 0) {
      activeSlide = slideCount - 1
    } else if (activeSlide >= slideCount) {
      //activeSlide = 0
      /* desired behavior: only cycle through carousel once and stop on last slide */
      activeSlide = slideCount - 1
      clearInterval(clearInterval)
    }
    this.setState({ activeSlide })
  }

  selectSlide(i) {
    clearInterval(this.interval)
    this.setState({ activeSlide: i })
    this.interval = setInterval(() => this.incrementSlide(true), 10000)
  }

  render() {
    return (
      <div className={styles.container}>
        <SwitchTransition mode="out-in">
          <CSSTransition
            timeout={500}
            classNames="background"
            key={this.state.activeSlide}
          >
            <div className={styles.backgroundImage}>
              {this.props.slides[this.state.activeSlide].featuredImage && (
                <Img
                  style={{ width: "100%", height: "100%" }}
                  fluid={
                    this.props.slides[this.state.activeSlide].featuredImage
                      .fluid
                  }
                />
              )}

              {!this.props.slides[this.state.activeSlide].featuredImage && (
                <img src={BackgroundPlaceholder} alt="" />
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
        <div className={styles.flag}>
          <CarouselMobileFlag />
        </div>
        {this.props.slides.map((item, i) => {
          if (i === this.state.activeSlide) {
            return (
              <FadingCarouselItem
                name={item.name}
                description={
                  item.carouselText ? item.carouselText : item.shortDescription
                }
                logo={item.logo}
                slug={item.slug}
                key={i}
                index={i}
                slideCount={this.props.slides.length}
                activeSlide={this.state.activeSlide}
                selectSlide={this.selectSlide}
                handleSwipe={this.incrementSlide}
              />
            )
          } else return null
        })}
      </div>
    )
  }
}

export default FadingCarousel
