import React from "react"
import { CSSTransition, SwitchTransition } from "react-transition-group"

import styles from "../styles/components/carousel.module.scss"
import ArrowBlockLeft from "../images/arrow-block-left.svg"
import ArrowBlockRight from "../images/arrow-block-right.svg"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

// function mod(n, m) {
//   return ((n % m) + m) % m
// }

class Carousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      index: 0,
      numColumns: 1,
      animationDirection: "carousel-right",
    }
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize = () => {
    if (window.innerWidth >= 1200 && this.state.numColumns !== 3) {
      this.setState(prevState => {
        return { numColumns: 3, index: 0 }
      })
    } else if (
      window.innerWidth >= 768 &&
      window.innerWidth < 1200 &&
      this.state.numColumns !== 2
    ) {
      this.setState(prevState => {
        return { numColumns: 2, index: 0 }
      })
    } else if (window.innerWidth < 768 && this.state.numColumns !== 1) {
      this.setState({ numColumns: 1, index: 0 })
    }
  }

  handleClickRight = () => {
    this.setState(prevState => ({
      index: prevState.index + this.state.numColumns,
      animationDirection: "carousel-right",
    }))
  }

  handleClickLeft = () => {
    this.setState(prevState => ({
      index: prevState.index - this.state.numColumns,
      animationDirection: "carousel-left",
    }))
  }

  render() {
    return (
      <div>
        <div className={cx(this.state.animationDirection, "container")}>
          <SwitchTransition mode="out-in">
            <CSSTransition
              classNames="carousel"
              timeout={400}
              key={this.state.index}
            >
              <div className={styles.itemsContainer}>
                {this.props.items &&
                  this.props.items.map((item, i) => {
                    var visible =
                      i - this.state.index >= 0 &&
                      i - this.state.index <= this.state.numColumns - 1
                    if (visible) {
                      return item
                    } else return null
                  })}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
        {this.props.items.length > this.state.numColumns && (
          <div className={styles.arrowsContainer}>
            <button
              className={cx("arrow", "left", {
                disabled: this.state.index === 0,
              })}
              disabled={this.state.index === 0}
              onClick={this.handleClickLeft}
              aria-label="Previous Items"
            >
              <ArrowBlockLeft />
            </button>
            <button
              className={cx("arrow", {
                disabled:
                  this.state.index + this.state.numColumns >=
                  this.props.items.length,
              })}
              disabled={
                this.state.index + this.state.numColumns >=
                this.props.items.length
              }
              onClick={this.handleClickRight}
              aria-label="Next Items"
            >
              <ArrowBlockRight />
            </button>
          </div>
        )}
      </div>
    )
  }
}

export default Carousel
