import React from "react"
import Fade from "react-reveal/Fade"
import Reveal from "react-reveal/Reveal"
import styles from "../../styles/components/home/home-hero.module.scss"
import "../../styles/animations.scss"

import FadingCarousel from "./fadingCarousel"

import Flag1 from "../../images/home-hero-flag-1.svg"
import Flag2 from "../../images/home-hero-flag-2.svg"
import Flag3 from "../../images/home-hero-flag-3.svg"
import Flag4 from "../../images/home-hero-flag-4.svg"
import CarouselBackdrop from "../../images/carousel-backdrop.svg"

class HomeHero extends React.Component {
  componentDidMount() {
    //add <strong> tag to "Flybridge" in header
    let header = document.querySelector(`div.${styles.header}`)
    let headerText = header.innerText
    if (header.innerText.indexOf("Flybridge") !== -1) {
      let idx1 = header.innerText.indexOf("Flybridge")
      let idx2 = idx1 + "Flybridge".length

      let headerHTML = headerText.substring(0, idx1)
      headerHTML = headerHTML + "<strong>Flybridge</strong>"
      headerHTML = headerHTML + headerText.substring(idx2)
      header.innerHTML = headerHTML
    }
    let anchors = document
      .getElementsByClassName(styles.subtext)[0]
      .getElementsByTagName("a")
    for (var i = 0; i < anchors.length; i++) {
      anchors[i].setAttribute("target", "_blank")
      anchors[i].setAttribute("rel", "noopener noreferrer")
    }
  }
  render() {
    return (
      <div className={styles.container}>
        <Reveal effect="fadeInOver" duration={750}>
          <div className={styles.flag4}>
            <Flag4 />
          </div>
        </Reveal>
        <div className={styles.carouselBackdrop}>
          <CarouselBackdrop />
        </div>
        <div className={styles.flag1}>
          <Flag1 />
        </div>
        <Fade left>
          <div className={styles.flag2}>
            <Flag2 />
          </div>
        </Fade>
        <Fade left>
          <div className={styles.flag3}>
            <Flag3 />
          </div>
        </Fade>
        <div className={styles.subcontainer}>
          <div className={styles.textGroup}>
            <div className={styles.text}>
              <div className={styles.header}>{this.props.header}</div>
              <div
                className={styles.subtext}
                dangerouslySetInnerHTML={{
                  __html: this.props.subtext.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
          <FadingCarousel slides={this.props.featuredCompanies} />
        </div>
      </div>
    )
  }
}

export default HomeHero
