import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Tweet from "./tweet"
import Carousel from "./carousel"

import styles from "../styles/components/twitter-section.module.scss"
import TwitterLogo from "../images/twitter-logo.svg"

class TwitterSection extends React.Component {
  constructor(props) {
    super(props)

    this.currentTime = new Date().getTime()
    this.dayDivisor = 1000 * 60 * 60 * 24
    this.hourDivisor = 1000 * 60 * 60

    this.tweets = this.props.data.allTwitterStatusesUserTimelineFlybridgeQuery.edges.map(
      (tweet, i) => {
        let tweetDate = new Date(tweet.node.created_at)
        return {
          time: this.getTimeDifference(tweetDate),
          content: this.getTweetHTML(tweet.node), //tweet.node.full_text, //.split(`http`)[0],
          link: `https://twitter.com/flybridge/status/${tweet.node.id_str}`,
        }
      }
    )
  }

  /* Returns string that describes difference in time between input date and current date*/
  getTimeDifference(inputDate) {
    let inputTime = inputDate.getTime()

    let dayDifference = Math.floor(
      (this.currentTime - inputTime) / this.dayDivisor
    )

    if (dayDifference >= 1) {
      return dayDifference + (dayDifference === 1 ? " Day" : " Days")
    } else {
      let hourDifference = Math.floor(
        (this.currentTime - inputTime) / this.hourDivisor
      )
      if (hourDifference < 1) return "Just Now"
      else if (hourDifference === 1) return hourDifference + " Hour"
      else return hourDifference + " Hours"
    }
  }

  //alters text so RTs aren't truncated
  getTweetHTML(tweetObject) {
    if (!tweetObject.retweeted_status) {
      return this.editEntities(tweetObject.full_text, tweetObject.entities)
    } else {
      let retweetObject = tweetObject.retweeted_status
      return `RT <a href=https://twitter.com/${
        retweetObject.user.screen_name
      } target="_blank" rel="noopener noreferrer">@${
        retweetObject.user.screen_name
      }</a>: ${this.editEntities(
        retweetObject.full_text,
        retweetObject.entities
      )}`
    }
  }

  //Add <a> tags to entity urls, mentions, and hashtags, and remove links to media urls
  editEntities(fullText, entities) {
    let newText = fullText
    if (entities.urls) {
      entities.urls.forEach(url => {
        newText = newText.replace(
          url.url,
          `<a href=${url.url} target="_blank" rel="noopener noreferrer">${url.display_url}</a>`
        )
      })
    }
    if (entities.media) {
      entities.media.forEach(media => {
        newText = newText.replace(media.url, "")
      })
    }
    if (entities.user_mentions) {
      entities.user_mentions.forEach(user => {
        newText = newText.replace(
          `@${user.screen_name}`,
          `<a href=https://twitter.com/${user.screen_name} target="_blank" rel="noopener noreferrer">@${user.screen_name}</a>`
        )
      })
    }
    if (entities.hashtags) {
      entities.hashtags.forEach(hashtag => {
        newText = newText.replace(
          `#${hashtag.text}`,
          `<a href=https://twitter.com/hashtag/${hashtag.text} target="_blank" rel="noopener noreferrer">#${hashtag.text}</a>`
        )
      })
    }
    return newText
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.label}>Twitter</div>
        <h2 className={styles.sectionTitle}>
          <a
            href="https://twitter.com/flybridge"
            target="_blank"
            rel="noopener noreferrer"
          >
            @flybridge
          </a>
        </h2>
        <Carousel
          items={this.tweets.map((tweet, i) => (
            <Tweet
              key={i}
              time={tweet.time}
              content={tweet.content}
              link={tweet.link}
            />
          ))}
        />
        <a
          className={styles.follow}
          href="https://twitter.com/flybridge"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterLogo />
          <span>Follow Us</span>
        </a>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allTwitterStatusesUserTimelineFlybridgeQuery {
          edges {
            node {
              created_at
              full_text
              id_str
              entities {
                urls {
                  display_url
                  url
                }
                media {
                  url
                }
                user_mentions {
                  id_str
                  screen_name
                }
                hashtags {
                  text
                }
              }
              retweeted_status {
                full_text
                user {
                  screen_name
                }
                entities {
                  urls {
                    display_url
                    url
                  }
                  media {
                    url
                  }
                  user_mentions {
                    id_str
                    screen_name
                  }
                  hashtags {
                    text
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <TwitterSection data={data} {...props} />}
  />
)
