import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TwitterSection from "../components/twitterSection"
import ContactSection from "../components/contactSection"

import HomeHero from "../components/home/homeHero"
import StaggeredGrid from "../components/home/staggeredGrid"
import OurValues from "../components/home/ourValues"
import OurApproach from "../components/home/ourApproach"

const IndexPage = props => {
  const { seo, sections } = props.data.allContentfulHomePage.edges[0].node

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      {sections.map((section, i) => {
        switch (section.label) {
          case "hero":
            return <HomeHero {...section} key={i} />

          case "our-family":
            return <StaggeredGrid {...section} key={i} />

          case "our-approach":
            return <OurApproach {...section} key={i} />

          case "our-values":
            return <OurValues {...section} key={i} />

          default:
            console.log("Section label not recognized")
            return null
        }
      })}
      <TwitterSection />
      <ContactSection />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    allContentfulHomePage {
      edges {
        node {
          seo {
            title
            description
            keywords
          }
          sections {
            label
            header
            subtext {
              childMarkdownRemark {
                html
              }
            }
            textList
            grid {
              tiles {
                ... on ContentfulCompany {
                  ...CompanyTile
                }
                ... on ContentfulPerson {
                  ...PersonTile
                }
                ... on ContentfulStatTile {
                  ...StatTile
                }
              }
              showFlagsOnTitle
            }
            buttonLabel
            pageLink
            externalLink
            featuredCompanies {
              name
              logo {
                fluid(maxWidth: 218, quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              featuredImage {
                fluid(maxHeight: 500, quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              carouselText
              slug
            }
          }
        }
      }
    }
  }
`
