import React from "react"
import Reveal from "react-reveal/Reveal"
import styles from "../../styles/components/home/our-approach.module.scss"
import Checkbox from "../../images/checkbox.svg"
import Button from "../button"

const OurApproach = props => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <Reveal effect="fadeIn">
          <div className={styles.header}>{props.header}</div>
        </Reveal>
        {props.subtext && (
          <Reveal effect="fadeInFromLeft">
            <div
              className={styles.subtext}
              dangerouslySetInnerHTML={{
                __html: props.subtext.childMarkdownRemark.html,
              }}
            />
          </Reveal>
        )}

        {props.buttonLabel && (
          <div className={styles.buttonWrapper}>
            <Button
              externalLink={props.externalLink}
              link={props.pageLink}
              black
              text={props.buttonLabel}
            />
          </div>
        )}
        {/* {props.buttonLabel && props.externalLink && (
          <a
            href="https://www.google.com"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.button}
          >
            <span>{props.buttonLabel}</span>
            <ArrowRight />
          </a>
        )}
        {props.buttonLabel && props.pageLink && (
          <Link to={props.pageLink} className={styles.button}>
            <span>{props.buttonLabel}</span>
            <ArrowRight />
          </Link>
        )} */}
      </div>
      <div className={styles.cardsContainer}>
        {props.textList.map((card, i) => {
          return (
            <Reveal effect="fadeIn" duration={i * 300} key={i}>
              <div className={styles.card}>
                {/* <div className={styles.circle} /> */}
                <Checkbox className={styles.checkbox} />
                <div>{card}</div>
              </div>
            </Reveal>
          )
        })}
      </div>
    </div>
  )
}

export default OurApproach
